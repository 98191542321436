<template>
    <div class="v-stack h-stretch gap-3">
        <div class="h-stack h-start gap-3">
            <router-link :to="'/system/settings/'">Settings</router-link>
            <router-link :to="'/system/groups/'">User Groups</router-link>
            <router-link :to="'/system/backup/'">Database Backups</router-link>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    beforeRouteEnter(to, from, next) {
        from;

        next((vm) => {
            if (to.name) {
                vm.$router.push(to.path + "/settings");
            }
        });
    },
}
</script>